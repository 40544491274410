import React from "react";
import DropdownMenu from "../Layout/DropdownMenu";
import "./IndexMapLegend.css";
import "../Layout/DropdownMenu.css";
import TemperatureScenarioSelector from "../Layout/TemperatureScenarioSelector";
import YearRangeSelector from "../Layout/YearRangeSelector";
import GradientLegend from "../Layout/GradientLegend";
import { wildfireStyle, heatwaveStyle, waterStressStyle, cycloneStyle, floodStyle, slrStyle } from "../Layout/LayerStyle";

const IndexMapLegend = (props) => {
  const {
    industry,
    hazards,
    hazardScoreMetadata,
    selectedHazard,
    setSelectedHazard,
    temperatureScenarios,
    selectedTemperatureScenario,
    setSelectedTemperatureScenario,
    yearRanges,
    selectedYearRange,
    setSelectedYearRange,
    assetPointsVisible,
    setAssetPointsVisible,
  } = props;

  const display_hazards = {
    "WILDFIRE": "wildfire",
    "FLOOD": "flood",
    "HEATWAVE": "heatwave",
    "CYCLONE": "cyclone",
    "SEA LEVEL RISE": "SLR",
    "WATER STRESS": "water_stress"
  };

  const legendOptions = ["ASSET TYPE", "WILDFIRE", "FLOOD", "HEATWAVE", "CYCLONE", "SEA LEVEL RISE", "WATER STRESS"];

  const asset_type_items_zeta = [
    { name: "Biomass", color: "#8D8741" },
    { name: "Coal", color: "#0E0B16" },
    { name: "Hydro", color: "#007CC7" },
    { name: "Natural Gas", color: "#B1A296" },
    { name: "Nuclear", color: "#6F2232" },
    { name: "Oil", color: "#4B4453" },
    { name: "Solar", color: "#FBB13C" },
    { name: "Wind", color: "#89DA59" },
  ];

  const asset_type_items_maple = [
    { name: "Wholesale Trade", color: "#8D8741" },
    { name: "Heavy", color: "#0E0B16" },
    { name: "Professional/Technical Services", color: "#007CC7" },
    { name: "Industrial", color: "#B1A296" },
    { name: "Medical Office/Clinic", color: "#6F2232" },
    { name: "High Technology", color: "#4B4453" },
    { name: "Residential", color: "#FBB13C" },
    { name: "Retail Trade", color: "#89DA59" },
    { name: "Temporary Lodging", color: "#5DEAF7" },
  ];

  const asset_type_items_ps = [
    { name: "Wind Project", color: "#89DA59" },
  ]

  const hazard_label_items = [
    { name: "Low", color: "#89DA59" },
    { name: "Medium", color: "#FBB13C" },
    { name: "High", color: "#ff0000" },
  ];

  const polygon_score_items = 
    { "wildfire": "Wildfire risk (%)",
      "flood": "Flood risk (%)",
      "cyclone": "Cyclone (CAT3+) risk (%)",
      "heatwave": "Heatwave days (%)",
      "SLR": "Sea level rise (m)",
      "water_stress": "Water stress risk (%)",
    };

  

  let items = hazards.includes(selectedHazard) ? hazard_label_items : industry === "Utilities" ? asset_type_items_zeta : industry === "Maple-1A" ? asset_type_items_maple : asset_type_items_ps;
  let scenarioLabel = selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
  let selectedHazardPropName = selectedHazard === 'SLR' ? 'sea_level_rise' : selectedHazard; // KEY

  let polyColorStyle = wildfireStyle;
  if (selectedHazard) {
    if (selectedHazard === "wildfire") {
      polyColorStyle = wildfireStyle;
    } else if (selectedHazard === "heatwave") {
      polyColorStyle = heatwaveStyle;
    }  else if (selectedHazard === "water_stress") {
      polyColorStyle = waterStressStyle;
    }  else if (selectedHazard === "cyclone") {
      polyColorStyle = cycloneStyle;
    }  else if (selectedHazard === "flood") {
      polyColorStyle = floodStyle;
    }  else if (selectedHazard === "SLR") {
      polyColorStyle = slrStyle;
    }
  }
  let gradientStartColor = polyColorStyle.paint["fill-color"][6];
  let gradientEndColor = polyColorStyle.paint["fill-color"][8];
  return (
    <div>

      <div className="map-legend">

      <DropdownMenu
        className="legend-dropdown"
        style={{ width: '500px' }}
        options={legendOptions}
        onSelect={(option) => {
          if (option === "asset type") {
            setSelectedHazard(null);
          } else {
            setSelectedHazard(display_hazards[option]);
          }
        }}
        label={"Layer Selector"}
      />
        {selectedHazard && (
          <>
          <h3 className="ssp-title">Risk Outlook</h3>
            
          </>
        )}

        {items.map((item) => (
          <div key={item.name} className="legend-item">
            <span
              className="legend-color"
              style={{ backgroundColor: item.color }}
            ></span>
            <span className="legend-text">{item.name}</span>
          </div>
        ))}

          <div className="asset-points-visibility">
            <input
              type="checkbox"
              checked={assetPointsVisible}
              onChange={(e) => setAssetPointsVisible(e.target.checked)}
              className="asset-points-visibility-checkbox"
            />
          <label className="asset-points-visibility-label">Show Asset Points</label>
        </div>
          <h3 className="ssp-title">Climate Scenario</h3>
            <TemperatureScenarioSelector 
              temperatureScenarios={temperatureScenarios}
              selectedTemperatureScenario={selectedTemperatureScenario}
              setSelectedTemperatureScenario={setSelectedTemperatureScenario}
              />
          { selectedHazard && (
          <>
          <h3 className="ssp-title">Forecast Period</h3>
          <YearRangeSelector
            yearRanges={yearRanges}
            selectedYearRange={selectedYearRange}
            setSelectedYearRange={setSelectedYearRange}
            />

          <h3 className="ssp-title">{polygon_score_items[selectedHazard]}</h3>
          <GradientLegend
            hazard={selectedHazard}
            startColor={gradientStartColor}
            endColor={gradientEndColor}
            startLabel={hazardScoreMetadata[scenarioLabel][selectedHazardPropName][selectedYearRange].min}
            endLabel={hazardScoreMetadata[scenarioLabel][selectedHazardPropName][selectedYearRange].max}
            />
           </>
          )}
      </div>
    </div>
  );
};

export default IndexMapLegend;

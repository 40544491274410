import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const StructDamageBarGraph = (props) => {
  const { selectedTemperatureScenario, selectedAssetProperties } = props;

  let scenarioLabel =
    selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";

  const analytics = JSON.parse(selectedAssetProperties.scenario_analytics);
  let hazardData = analytics[scenarioLabel].hazard;

  const structuralDamage = {
    Baseline: {
      Wildfire: hazardData.wildfire.structural_damage.baseline * 100,
      Flood: hazardData.flood.structural_damage.baseline * 100,
      Cyclone: hazardData.cyclone.structural_damage.baseline * 100,
      lbd:
        (hazardData.wildfire.structural_damage.baseline_lbd +
          hazardData.flood.structural_damage.baseline_lbd +
          hazardData.cyclone.structural_damage.baseline_lbd) *
        100,
      ubd:
        (hazardData.wildfire.structural_damage.baseline_ubd +
          hazardData.flood.structural_damage.baseline_ubd +
          hazardData.cyclone.structural_damage.baseline_ubd) *
        100,
    },
    2030: {
      Wildfire: hazardData.wildfire.structural_damage["2030"] * 100,
      Flood: hazardData.flood.structural_damage["2030"] * 100,
      Cyclone: hazardData.cyclone.structural_damage["2030"] * 100,
      lbd:
        (hazardData.wildfire.structural_damage["2030_lbd"] +
          hazardData.flood.structural_damage["2030_lbd"] +
          hazardData.cyclone.structural_damage["2030_lbd"]) *
        100,
      ubd:
        (hazardData.wildfire.structural_damage["2030_ubd"] +
          hazardData.flood.structural_damage["2030_ubd"] +
          hazardData.cyclone.structural_damage["2030_ubd"]) *
        100,
    },
    2050: {
      Wildfire: hazardData.wildfire.structural_damage["2050"] * 100,
      Flood: hazardData.flood.structural_damage["2050"] * 100,
      Cyclone: hazardData.cyclone.structural_damage["2050"] * 100,
      lbd:
        (hazardData.wildfire.structural_damage["2050_lbd"] +
          hazardData.flood.structural_damage["2050_lbd"] +
          hazardData.cyclone.structural_damage["2050_lbd"]) *
        100,
      ubd:
        (hazardData.wildfire.structural_damage["2050_ubd"] +
          hazardData.flood.structural_damage["2050_ubd"] +
          hazardData.cyclone.structural_damage["2050_ubd"]) *
        100,
    },
    2080: {
      Wildfire: hazardData.wildfire.structural_damage["2080"] * 100,
      Flood: hazardData.flood.structural_damage["2080"] * 100,
      Cyclone: hazardData.cyclone.structural_damage["2080"] * 100,
      lbd:
        (hazardData.wildfire.structural_damage["2080_lbd"] +
          hazardData.flood.structural_damage["2080_lbd"] +
          hazardData.cyclone.structural_damage["2080_lbd"]) *
        100,
      ubd:
        (hazardData.wildfire.structural_damage["2080_ubd"] +
          hazardData.flood.structural_damage["2080_ubd"] +
          hazardData.cyclone.structural_damage["2080_ubd"]) *
        100,
    },
  };

  const labels = ["Baseline", "2030", "2050", "2080"];

  const wildfireData = labels.map((label) => structuralDamage[label].Wildfire);
  const floodData = labels.map((label) => structuralDamage[label].Flood);
  const cycloneData = labels.map((label) => structuralDamage[label].Cyclone);


  const data = {
    labels: labels,
    title: "Structural Damage",
    labelColor: 'white',
    datasets: [
      {
        label: "Lower",
        data: labels.map((label) => structuralDamage[label].lbd),
        backgroundColor: '#505cc5',
        borderColor: '#505cc5',
        borderWidth: 3, 
        pointRadius: 2, 
        type: "line",
        stack: "stack 0",
      },
     {
       label: "Upper",
       data: labels.map((label) => structuralDamage[label].ubd),
       backgroundColor: 'rgba(255, 90, 90, 1)',
       borderColor: 'rgba(255, 90, 90, 1)',
       borderWidth: 3,
       pointRadius: 2,
       type: "line",
       stack: "stack 0",
     },
      {
        label: "Wildfire",
        data: wildfireData,
        backgroundColor: "#fd8d3c",
        stack: "stack 1",
      },
      {
        label: "Flood",
        data: floodData,
        backgroundColor: "#6baed6",
        stack: "stack 1",
      },
      {
        label: "Cyclone",
        data: cycloneData,
        backgroundColor: "#74c476",
        stack: "stack 1",
      },
    ],
  };




  const options = {
    indexAxis: "x",
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {      
          color: 'gray',
      },
        stacked: true,
        beginAtZero: true,
      },
      y: {
        stacked: true,
        ticks: {      
          color: 'gray',
      },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 15,
          color: 'gray',
        },
      },
      title: {
        display: true,
        text: "Structural Damage (% of Asset Value)",
        color: "gray",
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default StructDamageBarGraph;

import React, {useContext, useEffect, useState} from 'react';
import {authContext} from "../../context/authContext";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const UserProfile = () => {
    useEffect(() => {
        document.title = "User Profile";
    }, []);
    const [errors, setErrors] = useState('');
    const [email, setEmail] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [keyVisible, setKeyVisible] = useState(false);
    const user = useContext(authContext);

    useEffect(() => {
        if (user) {
            setEmail(user.email)
            user.getIdTokenResult()
                .then((token) => {
                    if (!!token.claims.api_key) {
                        setApiKey(token.claims.api_key)
                    } else {
                        console.error('api key not available for user')
                    }
                })
                .catch((error) => {
                    setErrors(error)
                });
        }
    }, [user])

    const copyToClipboard = () => {
        navigator.clipboard.writeText(apiKey)
            .then(() => {
                console.log('API Key copied to clipboard')
            })
            .catch((error) => {
                console.error(error)
                setErrors(error)
            })
    }

    return (<>
        <main>
            <section style={{marginTop: 30}}>
                <div>
                    <div>
                        <section>
                            <div className="messages d-flex justify-content-center centered-form"
                                 style={{display: "inline-block"}}>
                                {errors && <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    style={{color: "red"}}>
                                    {errors && errors}
                                </div>}
                            </div>
                            <div className="d-flex justify-content-center centered-form">
                                <form>
                                    <h3 style={{textAlign: "center"}}>User Profile</h3>
                                    <div style={{textAlign: "left"}}>
                                        <div className="form-group mt-2">
                                            Email Address
                                            <input type="email" id="email" name="email"
                                                   value={email}
                                                   className="textinput form-control"
                                                   maxLength="254"
                                                   size="35"
                                                   disabled={true}/>
                                        </div>
                                        <div className="form-group mt-2">
                                            Password
                                            <input type="password" id="password" name="password"
                                                   value={"******************"}
                                                   className="textinput form-control"
                                                   disabled={true}/>
                                            <div className="mt-2" style={{textAlign: "center"}}>
                                                <a style={{fontWeight: 450, textDecoration: "none"}}
                                                   href={'/account/password'}>Change
                                                    Password</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5" style={{textAlign: "left"}}>
                                        API Key
                                        <div className="input-group">
                                            <input type={keyVisible ? "text" : "password"} id="api_key"
                                                   name="api_key"
                                                   value={apiKey}
                                                   className="textinput form-control"
                                                   disabled={true}/>
                                            <IconButton onClick={() => setKeyVisible(!keyVisible)}>
                                                {keyVisible ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                            </IconButton>
                                            <IconButton onClick={() => copyToClipboard()}>
                                                <ContentCopyIcon/>
                                            </IconButton>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </main>
    </>)
}

export default UserProfile
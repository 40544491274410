import React, { useState, useEffect } from "react";
import "./MapFilter.css";
import DropdownMenu from "./DropdownMenu";

const MapFilter = (props) => {
  const {
    industries,
    indexList,
    companies,
    selectedIndustry,
    setSelectedIndex,
    setSelectedIndustry,
    setSelectedCompany,
    setGraphPanelVisible,
  } = props;

  const downloadFile = (filePath, fileName) => {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = () => {
    var filePath; 
    if (selectedIndustry === "Utilities") {
      filePath = "/ZETA_utilities_top20.csv";
    } else if (selectedIndustry === "Maple1A") {
      filePath = "/MAPLE1A.csv";
    } else if (selectedIndustry === "Power_Sustainable") {
      filePath = "/MAPLE1A1k.csv";
    }
    var fileName; 
    if (selectedIndustry === "Utilities") {
      fileName = "ZETA_utilities_top20.csv";
    } else if (selectedIndustry === "Maple1A") {
      fileName = "MAPLE1A.csv";
    } else if (selectedIndustry === "Power_Sustainable") {
      fileName = "MAPLE1A1k.csv";
    }
    downloadFile(filePath, fileName);
  };

  return (
    <div className="filter-panel">
      <div className="filter-container">
        <div className="selector-container">
          <DropdownMenu
            style={{ width: "260px" }}
            options={industries}
            onSelect={setSelectedIndustry}
            label={"Portfolio"}
          />
        </div>
        <div className="selector-container">
          <DropdownMenu
            options={companies}
            onSelect={setSelectedCompany}
            label={"Company"}
          />
        </div>
        <div className="selector-container">
          <button className="summary-button" onClick={() => setGraphPanelVisible(true)}>Summary</button>
          
        </div>
        <div className="selector-container">
          <button className="summary-button" onClick={() => handleDownload()}>Download</button>
      </div>
      </div>
    </div>
  );
};

export default MapFilter;

import React, { useState, useEffect } from "react";
import "./GraphPanel.css";
import ExposureBarGraph from "../Graph/ExposureBarGraph";
import ExposureHistogram from "../Graph/ExposureHistogram";
import StructDamageBarGraph from "../Graph/StructDamageBarGraph";
import BusinessInterruptBarGraph from "../Graph/BusinessInterruptBarGraph";
import PeerCompareCompanyBarGraph from "../Graph/PeerCompareCompanyBarGraph";
import PeerCompareIndustryBarGraph from "../Graph/PeerCompareIndustryBarGraph";
import graphDescriptions from "../../data/text/graphDescriptions";

const GraphPanel = (props) => {
  const {
    selectedCompany,
    selectedAssetProperties,
    handleGraphPanelClose,
    activeMapData,
    selectedTemperatureScenario,
    selectedIndustryData,
    histogramData,
  } = props;
  
  return (
    
      <div className="graph-panel-container">
        <div className="graph-panel-header">
          {selectedAssetProperties && (
            <div>
              <h5>{selectedAssetProperties.issuer_name}</h5>
              <h5>{selectedAssetProperties.asset_name}</h5>
              <h5>{selectedAssetProperties.asset_address}</h5>
              <div style={{"display":"inline-block", "margin-right":"10px"}}>
                <h5>Financial Exposure Score: {(selectedAssetProperties.financial_exposure_weight.toFixed(4))}</h5>
              </div>
            </div>
          )}
          <div
            className="graph-panel-close-button"
            onClick={() => handleGraphPanelClose()}
          >
            X
          </div>
        </div>
        <div className="grid-container">
          {selectedAssetProperties && (
            <>
              <div className="grid-item grid-text">
              <div className="row-title">Financial Impact</div>
                {graphDescriptions["Financial Impact"]}
              </div>
              <div className="grid-item">
                <StructDamageBarGraph
                  selectedAssetProperties={selectedAssetProperties}
                  selectedTemperatureScenario={selectedTemperatureScenario}
                />
              </div>
              <div className="grid-item">
                <BusinessInterruptBarGraph
                  selectedAssetProperties={selectedAssetProperties}
                  selectedTemperatureScenario={selectedTemperatureScenario}
                />
              </div>
            </>
          )}
          {selectedAssetProperties && (
            <>
              <div className="grid-item grid-text">
              <div className="row-title">Exposure</div>
                {graphDescriptions.Exposure}
              </div>
              <div className="grid-item">
                <ExposureBarGraph
                  selectedAssetProperties={selectedAssetProperties}
                  selectedTemperatureScenario={selectedTemperatureScenario}
                />
              </div>
              <div className="grid-item">
                <div style={{"color":"gray", "text-align":"center", "font-weight":"bold"}}>Distribution of Exposure across portfolio</div>
                <ExposureHistogram
                  selectedAssetProperties={selectedAssetProperties}
                  histogramData={histogramData}
                  selectedTemperatureScenario={selectedTemperatureScenario}
                />
              </div>
            </>
          )}
          <div className="grid-item grid-text">
          <div className="row-title">Peer Comparison</div>
            {graphDescriptions["Peer Comparison"]}
          </div>
          <div className="grid-item">
            <PeerCompareIndustryBarGraph
              selectedIndustryData={selectedIndustryData}
              type="Structural Damage"
              selectedTemperatureScenario={selectedTemperatureScenario}
            />
          </div>
          <div className="grid-item">
            <PeerCompareIndustryBarGraph
              selectedIndustryData={selectedIndustryData}
              type="Business Interruption"
              selectedTemperatureScenario={selectedTemperatureScenario}
            />
          </div>
        </div>
      </div>
    
  );
};

export default GraphPanel;

import React, { useState, useEffect } from "react";
import "./Index.css";
import IndexMap from "./IndexMap";
import indexData from "../../data/portfolios.json";

const Index = () => {
  const hazards = [
    "wildfire",
    "flood",
    "cyclone",
    "heatwave",
    "SLR",
    "water_stress",
  ];
  const scenarios = ["ssp245", "ssp585"];

  const createTopLevelProperty = (data, hazard, scenario, propertyName) => {
    data.forEach(
      (item) =>
        (item.geojson.features = item.geojson.features.map((feature) => {
          const hazardLabel =
            feature.properties.scenario_analytics[scenario].hazard[hazard][
              propertyName
            ];
          const hazardProptype = `${hazard}_${scenario}_${propertyName}`;
          return {
            ...feature,
            properties: {
              ...feature.properties,
              [hazardProptype]: hazardLabel, // Add the nested property to the top level
            },
          };
        }))
    );
  };

  hazards.forEach((hazard) => {
    scenarios.forEach((scenario) => {
      createTopLevelProperty(indexData, hazard, scenario, "summary_label");
    });
  });



  const createHistogramData = (data, hazard, scenario, propertyName) => {
    let histogramData = [];
    data.forEach((collection) => {
      collection.geojson.features.forEach((feature) => {
        const value = feature.properties.scenario_analytics[scenario].hazard[hazard][propertyName];
        histogramData.push(value);
      });
    });
    const maxScore = Math.max(...histogramData);
    const minScore = Math.min(...histogramData);
    const binSize = (maxScore - minScore) / 10;
    let bins = new Array(10).fill(0);
    histogramData.forEach((score) => {
      const binIndex = Math.min(Math.floor((score - minScore) / binSize), 9); 
      bins[binIndex]++;
    });
    let breaks = [];
    breaks.push(minScore);
    for (let i = 1; i < 10; i++) {
      breaks.push(minScore + i * binSize);
    }
    return [bins, breaks];
  };



  const histogramData = {
    "ssp245": {
        "wildfire": createHistogramData(indexData, "wildfire", "ssp245", "summary_score"),
        "flood": createHistogramData(indexData, "flood", "ssp245", "summary_score"),
        "cyclone": createHistogramData(indexData, "cyclone", "ssp245", "summary_score"),
        "heatwave": createHistogramData(indexData, "heatwave", "ssp245", "summary_score"),
        "SLR": createHistogramData(indexData, "SLR", "ssp245", "summary_score"),
        "water_stress": createHistogramData(indexData, "water_stress", "ssp245", "summary_score"),
  },
    "ssp585" : {
      "wildfire": createHistogramData(indexData, "wildfire", "ssp585", "summary_score"),
      "flood": createHistogramData(indexData, "flood", "ssp585", "summary_score"),
      "cyclone": createHistogramData(indexData, "cyclone", "ssp585", "summary_score"),
      "heatwave": createHistogramData(indexData, "heatwave", "ssp585", "summary_score"),
      "SLR": createHistogramData(indexData, "SLR", "ssp585", "summary_score"),
      "water_stress": createHistogramData(indexData, "water_stress", "ssp585", "summary_score"),
    }
  };


  return (
    <div id="index-container">
      <IndexMap
        indexData={indexData}
        histogramData={histogramData}
        hazards={hazards}
      />
    </div>
  );
};

export default Index;

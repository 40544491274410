
// Controls color and size of asset points at different zoom levels. ONLY NEED TO CHANGE THESE VARIABLES TO CHANGE POINT STYLE
const z5PointRadiusMin = 2;
const z5PointRadiusMax = 25;

const z10PointRadiusMin = 5;
const z10PointRadiusMax = 105;

// Controls color and size of asset points
const assetTypeStyle = {
  id: "point-layer",
  type: "circle",
  paint: {
      'circle-radius':  [
        'interpolate',
        ['linear'],
        ['zoom'],
        5, [ // Size at zoom level 5
          'interpolate',
          ['linear'], // Interoplate circle size linearly
          ['get', 'financial_exposure_weight'],
          0, z5PointRadiusMin, // If financial_exposure_weight is 0, circle radius is 2
          0.05, z5PointRadiusMax  // If financial_exposure_weight is 0.01, circle radius is 25
        ],
        10, [ // Size at zoom level 10
          'interpolate',
          ['linear'], // Interoplate circle size linearly
          ['get', 'financial_exposure_weight'],
          0, z10PointRadiusMin, // If financial_exposure_weight is 0, circle radius is 5
          0.05, z10PointRadiusMax // If financial_exposure_weight is 0.01, circle radius is 105
        ]
      ],
      'circle-color': [
        'match',
        ['get', 'type'], // Get the 'type' property from each feature
        'Biomass', '#8D8741',
        'Coal', '#0E0B16',
        'Hydro', '#007CC7',
        'Natural Gas', '#B1A296',
        'Nuclear', '#6F2232',
        'Oil', '#4B4453',
        'Solar', '#FBB13C',
        'Wind', '#89DA59',
        'Wind Project', '#89DA59',
        'Wholesale Trade', '#8D8741',
        'Heavy', '#0E0B16',
        'Professional/Technical Services', '#007CC7',
        'Industrial', '#B1A296',
        'Medical Office/Clinic', '#6F2232',
        'High Technology', '#4B4453',
        'Residential', '#FBB13C',
        'Retail Trade', '#89DA59',
        'Temporary Lodging', '#5DEAF7',
        '#ccc' // Default color if none of the above types match
      ]
    }
};

const hazardLabelStyle = {
  id: "point-layer",
  type: "circle",
  paint: {
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      5, [
        'interpolate',
        ['linear'],
        ['get', 'financial_exposure_weight'],
        0, z5PointRadiusMin, 
        0.05, z5PointRadiusMax 
      ],
      10, [
        'interpolate',
        ['linear'],
        ['get', 'financial_exposure_weight'],
        0, z10PointRadiusMin,
        0.05, z10PointRadiusMax
      ]
    ],
    'circle-color': [
      'match',
      ['get', 'hazard_summary_label'], 
      'LOW', '#89DA59',
      'MEDIUM', '#FBB13C',
      'HIGH', '#ff0000',
      '#ccc' 
    ]
  }
};

const pointBorder = {
  id: 'border-layer',
  type: 'circle',
  paint: {
    'circle-color': '#ffffff',
    'circle-radius':  [
      'interpolate',
      ['linear'],
      ['zoom'],
      5, [
        'interpolate',
        ['linear'],
        ['get', 'financial_exposure_weight'],
        0, z5PointRadiusMin + 1, 
        0.05, z5PointRadiusMax + 1 
      ],
      10, [
        'interpolate',
        ['linear'],
        ['get', 'financial_exposure_weight'],
        0, z10PointRadiusMin + 1,
        0.05, z10PointRadiusMax + 1
      ]
    ],
  }
};

// Polygon style for wildfire
const wildfireStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        // ['exponential', 3],
        ['linear'],
        ['get', 'hazard_summary_score'],
        0, 'rgba(0, 0, 0, 0)', // Lowest limit value and color: WILL ALWAYS BE FULLY TRANSPARENT
        0.0001, 'rgba(255, 0, 180, 0.1)', // Lower limit value and color
        0.99, 'rgba(255, 0, 180, 1)' // Upper limit value and color
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for heatwave
const heatwaveStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        // ['exponential', 3],
        ['linear'],
        ['get', 'hazard_summary_score'],
        0, 'rgba(0, 0, 0, 0)', // Lowest limit value and color: WILL ALWAYS BE FULLY TRANSPARENT
        0.1, 'rgba(255, 0, 180, 0)', // Lower limit value and color
        0.2, 'rgba(255, 0, 180, 1)', // Middle limit value and color
        0.99, 'rgba(255, 0, 180, 1)' // Upper limit value and color
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for water stress
const waterStressStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        // ['exponential', 3],
        ['linear'],
        ['get', 'hazard_summary_score'],
        0, 'rgba(0, 0, 0, 0)', // Lowest limit value and color: WILL ALWAYS BE FULLY TRANSPARENT
        0.3, 'rgba(255, 0, 180, 0)', // Lower limit value and color
        0.99, 'rgba(255, 0, 180, 1)' // Upper limit value and color
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for flood
const floodStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'hazard_summary_score'],        
        0, 'rgba(0, 0, 0, 0)',
        0.0001, 'rgba(0, 255, 255, 0.1)',
        0.99, 'rgba(0, 255, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for cyclone
const cycloneStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'hazard_summary_score'],        
        0, 'rgba(0, 0, 0, 0)',
        0.0001, 'rgba(0, 255, 255, 0.1)',
        0.99, 'rgba(0, 255, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// Polygon style for sea level rise
const slrStyle = {
  id: "polygon-layer",
  type: "fill",
  paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'hazard_summary_score'],        
        0, 'rgba(0, 0, 0, 0)',
        0.0001, 'rgba(0, 255, 255, 0.1)',
        0.99, 'rgba(0, 255, 255, 1)'
      ],
      'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
    }
};

// EXAMPLES

// Polygon style for wildfire, heatwave, and water stress
// const hazardScorePolygonStyleHot = {
//   id: "polygon-layer",
//   type: "fill",
//   paint: {
//       'fill-color': [
//         'interpolate',
//         // ['exponential', 3],
//         ['linear'],
//         ['get', 'hazard_summary_score'],
//         0, 'rgba(0, 0, 0, 0)', // Lowest limit value and color: WILL ALWAYS BE FULLY TRANSPARENT
//         0.0001, 'rgba(255, 0, 0, 0.1)', // Lower limit value and color
//         0.99, 'rgba(255, 0, 0, 1)' // Upper limit value and color: UPPER LIMIT VALUE IS DYNAMICALLY REPLACED FROM META DATA
//       ],
//       'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
//     }
// };

// Polygon style for flood, cyclone, and sea level rise
// const hazardScorePolygonStyleCool = {
//   id: "polygon-layer",
//   type: "fill",
//   paint: {
//       'fill-color': [
//         'interpolate',
//         ['linear'],
//         ['get', 'hazard_summary_score'],        
//         0, 'rgba(0, 0, 0, 0)',
//         0.0001, 'rgba(47, 0, 255, 0.1)',
//         0.99, 'rgba(47, 0, 255, 1)'
//       ],
//       'fill-outline-color': 'rgba(242, 229, 229, 0.1)'
//     }
// };


export { 
  assetTypeStyle,
  hazardLabelStyle,
  pointBorder,
  wildfireStyle,
  heatwaveStyle,
  waterStressStyle,
  cycloneStyle,
  floodStyle,
  slrStyle,
   };
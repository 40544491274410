import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
  );




const PeerCompareCompanyBarGraph = (props) => {
    const { 
        selectedTemperatureScenario,
        activeMapData,
     } = props;

    let scenarioLabel =  selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
    const hazardTypes = ["Wildfire", "Flood", "Cyclone"];

    let assetAddresses = activeMapData.features.map(feature => {
        const wildfire2050 = feature.properties.scenario_analytics[scenarioLabel].hazard.wildfire.structural_damage['2050'] * 100;
        const flood2050 = feature.properties.scenario_analytics[scenarioLabel].hazard.flood.structural_damage['2050'] * 100;
        const cyclone2050 = feature.properties.scenario_analytics[scenarioLabel].hazard.cyclone.structural_damage['2050'] * 100;
        const total = Math.round((wildfire2050 + flood2050 + cyclone2050) * 1000);
        return {
            asset_address: feature.properties.asset_address,
            Wildfire: wildfire2050,
            Flood: flood2050,
            Cyclone: cyclone2050,
            total_risk: Number(total)
        };
    });

    assetAddresses = assetAddresses.sort((a, b) => (b.total_risk - a.total_risk));
    assetAddresses = assetAddresses.slice(0, 10)

    const labelNames = assetAddresses.map(item => item.asset_address.slice(0,10));
    const wildfireData = assetAddresses.map(item => item.Wildfire);
    const floodData = assetAddresses.map(item => item.Flood);
    const cycloneData = assetAddresses.map(item => item.Cyclone);
    


    const data = {
      labels: labelNames,
      datasets: [
        {
          label: 'Wildfire',
          data: wildfireData,
          backgroundColor: '#fd8d3c',
        },
        {
          label: 'Flood',
          data: floodData,
          backgroundColor: '#6baed6',
        },
        {
          label: 'Cyclone',
          data: cycloneData,
          backgroundColor: '#74c476',
        }
      ],
    };
    
    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            beginAtZero: true,
            ticks: {
                color: 'gray', 
              },
          },
          y: {
            stacked: true,
            ticks: {
                color: 'gray',
                font: {
                  size: 7, 
                },
                
              },
          },
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                  boxWidth: 15,
                  color: 'gray',
                },
            },
            title: {
              display: true,
              text: 'Top Risk Assets for Company',
              color: 'gray',
          },
        },
      };


    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#000'}}>
            <Bar data={data} options={options}/>
        </div>
    )
}


export default PeerCompareCompanyBarGraph;